html {
  background-color: black;
}

 .App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("https://wallpaperset.com/w/full/c/1/4/427159.jpg");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




#headerMoon {
  margin-left: 1vw;
  float: left;
  height: 7vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  #headerMoon {
    animation: headerMoon-spin infinite 20s linear;
  }
}

@keyframes headerMoon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} 


#postContainer {
  margin-top: 40vh;
  padding: 10vh;
  height: auto;
  width: 100vw;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
}


.postCard {
  margin: auto;
  margin-top: 40vh;
  margin-bottom: 10vh;
  background-color: rgb(249, 253, 255);
  height: auto;
  width: 80%;
  font-size:70%;
  border-radius: 3vh;
  padding: 2vh;
  color: rgb(0, 253, 51);
  background-color: rgb(35, 38, 50);
  box-shadow: 0px 5px 25px rgb(249, 249, 249);
}






#content {
  background-color: rgb(231, 217, 217);
  color: rgb(0, 0, 0);
  border-radius: 1vh;
  min-height: 10vh;
  margin: auto;
  padding: 6.5vh;
  max-width: 30%;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.header {
  background-color: rgb(63, 134, 179);
  position: absolute;
  top: 0;
  width: 100vw;
  padding: 1vh;
  height: fit-content;
  color: rgb(255, 255, 255);
}

#headerHome {
  background-color: rgb(63, 134, 179);
  position: absolute;
  top: 0;
  width: 100vw;
  height: fit-content;
  color: rgb(255, 255, 255);
  font-size: 70%;
  margin-bottom: 50vh;
}



#postForm {
  margin-top: 30vh;
  font-weight: bolder;
  font-size: 80%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 3vw;
  height: auto;
  width: 70%;
  border-radius: 3vh;
  margin: auto;
  box-shadow: 0px 5px 25px rgb(249, 249, 249);
  opacity: 0;
  animation: fade 0.6s ease-in 0.09s;
  animation-fill-mode: forwards;
  margin-bottom: 30vh;
}

@keyframes fade {
  0%{
    opacity: 0
  }
  50% {
    opacity: 0.5;
  }
  100%{
    opacity: 1
  }
}

.postFormInput {
  border-radius: 3vh;
  font-size: 90%;
  font-weight: bolder;
  text-align: center;
  padding: 2vh;
  margin-top: 2vh;
  margin-bottom: 3vh;
  color: rgb(0, 253, 51);
  background-color: rgb(35, 38, 50);
}


#avatar {
  float: right;
  height: 7vmin;
  margin-right: 1vw;
  border-radius: 36vh;
}

.postImage {
  margin-top: 3vh;
  height: 55%;
  width: 55%;
  border-radius: 1vh;
  margin-bottom: 3vh;
}

.headerButton {
  border-radius: 1.6vh;
  line-height: 1.2em;
  font-weight: bolder;
  font-size: 60%;
  padding: 0.5em;
  margin-inline: 0.5vw;
  background-color: rgb(10, 41, 70);
  color: rgb(12, 173, 222);
  transition: 0.3s;
  /* min-width: 25%; */
  min-width: 18%;
}

.headerButton:hover {
  translate: -5px -5px -5px;
  box-shadow: -4px 5px 25px rgb(249, 249, 249);
  color: rgb(255, 255, 255);
}

#home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: max-content;
}

.home-button {
  box-shadow: 0px -1px 6px rgb(0, 253, 51);
  border-radius: 1.6vh;
  min-width: 200px;
  line-height: 1.1em;
  font-weight: bolder;
  font-size: x-large;
  padding: 0.4em;
  margin-bottom:5vh;
  color: rgb(0, 253, 51);
  background-color: rgb(35, 38, 50);
  transition: 0.3s;
  opacity: 0;
  animation: fade 0.8s ease-in 0.12s;
  animation-fill-mode: forwards;
}

.home-button:hover {
  translate: -5px -5px -5px;
  box-shadow: 0px 5px 25px rgb(249, 249, 249);
  color: rgb(255, 255, 255);
}

#homeMoon {
  height: 40vmin;
  margin-top: 20vh;
  margin-bottom: 15vh;
}

@media (prefers-reduced-motion: no-preference) {
  #homeMoon {
    animation: homeMoon-spin infinite 20s linear;
  }
}

@keyframes homeMoon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} 

.errors {
  color: red;
  font-size: xx-large;
  text-align: center;
  margin-top: 10vh;
  margin-bottom: 10vh;
}

#commenter {
  height: 10vmin;
  width: 10vmin;
  border-radius: 30vmin;
}

#postCommentImage {
  margin-top: 3vh;
  height: 55%;
  width: 55%;
  border-radius: 1vh;
  margin-bottom: 3vh;
}



.commentContainer {
  margin-top: 40vh;
  padding: 10vh;
  height: auto;
  width: 100vw;
  align-content: center;
  display: grid;
  gap: 5vh;
  grid-template-rows: repeat(1, 1fr);
}

.commentCard {
  margin: auto;
  margin-top: 30vh;
  margin-bottom: 5vh;
  border-radius: 3vh;
  height: auto;
  width: 80%;
  font-size:70%;
  padding: 2vh;
  color: rgb(0, 253, 51);
  box-shadow: 0px 5px 25px rgb(249, 249, 249);
}


  #commentForm {
  font-weight: bolder;
  font-size: 80%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 3vw;
  height: auto;
  width: 70%;
  border-radius: 3vh;
  margin: auto;
  box-shadow: 0px 5px 25px rgb(249, 249, 249);
  opacity: 0;
  animation: fade 0.8s ease-in 0.09s;
  animation-fill-mode: forwards;
  margin-bottom: 40vh;
  
  }

  #commentInput {
    border-radius: 3vh;
    font-size: 80%;
    font-weight: bolder;
    text-align: center;
    padding: 2vh;
    margin-top: 2vh;
    margin-bottom: 2vh;
    color: rgb(0, 253, 51);
    background-color: rgb(35, 38, 50);
  }

  #commentFormButton {
    border-radius: 3vh;
    font-size: 80%;
    font-weight: bolder;
    text-align: center;
    padding: 2vh;
    margin-top: 3vh;
    margin-bottom: 2vh;
    color: rgb(0, 253, 51);
    background-color: rgb(35, 38, 50);
  }

  .commentButton {
  box-shadow: 0px -1px 6px rgb(0, 253, 51);
  border-radius: 1.6vh;
  min-width: 200px;
  margin-inline: 1vw;
  line-height: 1.1em;
  font-weight: bolder;
  font-size: x-large;
  padding: 0.4em;
  transition: 0.3s;
  margin-bottom:5vh;
  color: rgb(0, 253, 51);
  background-color: rgb(35, 38, 50);
  }

  .commentButton:hover {
    translate: -5px -5px -5px;
    box-shadow: 0px 5px 25px rgb(249, 249, 249);
    color: rgb(255, 255, 255);
  }

  #commentButtonDisplay {
    color: rgb(0, 253, 51);
    background-color: rgb(35, 38, 50);
    box-shadow: 0px 5px 25px rgb(249, 249, 249);
    border-radius: 2vh;
    font-size: 60%;
    font-weight: bolder;
    padding: 1.5vh;
    margin-bottom: 15vh;
  }

  ::placeholder {
    color: rgb(0, 229, 255);
  }

  #commentSelect {
    border-radius: 3vh;
    font-size: 80%;
    font-weight: bolder;
    text-align: center;
    padding: 2vh;
    margin-top: 1vh;
    color: rgb(0, 229, 255);
    background-color: rgb(35, 38, 50);
  }

  #commentCardBody {
  background-color: rgb(35, 38, 50);
  color: rgb(255, 255, 255);
  border-radius: 1vh;
  min-height: 10vh;
  margin: auto;
  padding: 6.5vh;
  max-width: 30%;
  margin-top: 3vh;
  margin-bottom: 3vh; 
  }

  #loginForm {
    font-weight: bolder;
    font-size: 80%;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 3vh;
    height: auto;
    width: 80%;
    border-radius: 3vh;
    margin: auto;
    box-shadow: 0px 5px 25px rgb(249, 249, 249);
  }

  #signupForm {
    margin: auto;
    font-weight: bolder;
    font-size: 80%;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 3vh;
    height: auto;
    width: 80%;
    border-radius: 3vh;
    margin: auto;
    box-shadow: 0px 5px 25px rgb(249, 249, 249);
  }

  #userPage {
    text-align: center;
    color: white;
  }

  #username {
    color: rgb(0, 229, 255);
  }

  #homeAvatar {
    height: 40%;
    width: 40%;
    border-radius: 36vh;
  }

  #postFormButton {
    color: rgb(0, 253, 51);
    background-color: rgb(35, 38, 50);
    box-shadow: 0px 5px 25px rgb(249, 249, 249);
    border-radius: 2vh;
    font-size: 60%;
    font-weight: bolder;
    padding: 1.5vh;
    margin-bottom: 30vh;
  }



  .loginInput {
    border-radius: 2vh;
    font-size: larger;
    font-weight: bolder;
    text-align: center;
    padding: 3vh;
    margin-top: 2vh;
    margin-bottom: 3vh;
    height: auto;
    color: rgb(0, 253, 51);
    background-color: rgb(35, 38, 50);
  }

  .signupInput {
    border-radius: 2vh;
    font-size: larger;
    font-weight: bolder;
    text-align: center;
    padding: 3vh;
    margin-top: 2vh;
    margin-bottom: 1vh;
    height: auto;
    color: rgb(0, 253, 51);
    background-color: rgb(35, 38, 50);
  }

#commentTitle {
  color: white;
}

#postList {
  color: white;
}

#editCommentForm {
  margin: auto;
  font-weight: bolder;
  font-size: 80%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 3vh;
  height: auto;
  width: 80%;
  border-radius: 3vh;
  margin: auto;
  box-shadow: 0px 5px 25px rgb(249, 249, 249);
}

.editFormInput {
  border-radius: 2vh;
    font-size: larger;
    font-weight: bolder;
    text-align: center;
    padding: 3vh;
    margin-top: 2vh;
    margin-bottom: 1vh;
    height: auto;
    color: rgb(0, 253, 51);
    background-color: rgb(35, 38, 50);
  }

  .no-access {
    color: white;
  }

  #homePost {
    height: 5vmin;
    width: 5vmin;
  }